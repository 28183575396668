/* eslint-disable no-underscore-dangle */

import { createStore } from 'redux';
import rootReducer from './appReducer';

export default function configureStore(initialState = {}) {
  const store = createStore(rootReducer, initialState);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./appReducer', () => {
      const nextRootReducer = require('./appReducer'); // eslint-disable-line global-require
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
