const UPDATE_SCROLL = 'viewport/UPDATE_SCROLL';
const UPDATE_RESIZE = 'viewport/UPDATE_RESIZE';
const OPEN_MODAL = 'campaign/OPEN_MODAL';
const CLOSE_MODAL = 'campaign/CLOSE_MODAL';
const NAV_SCROLL = 'nav/SCROLL';

const initialState = {
  scrollX: 0,
  scrollY: 0,
  windowWidth: 0,
  windowHeight: 0,
  modalOpen: null,
  modalData: null,
  navActive: null,
};

/**
 * Main reducer
 */
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_SCROLL:
      return {
        ...state,
        scrollX: action.scrollX,
        scrollY: action.scrollY,
      };
    case UPDATE_RESIZE:
      return {
        ...state,
        windowWidth: action.windowWidth,
        windowHeight: action.windowHeight,
      };
    case OPEN_MODAL:
      return {
        ...state,
        modalOpen: action.modalType,
        modalData: action.modalData,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modalOpen: null,
      };
    case NAV_SCROLL:
      return {
        ...state,
        navActive: action.navActive,
      };
    default:
      return state;
  }
};

/**
 * Actions
 */
export function resizeAction(windowWidth, windowHeight) {
  return {
    type: UPDATE_RESIZE,
    windowWidth,
    windowHeight,
  };
}

export function scrollAction(scrollX, scrollY) {
  return {
    type: UPDATE_SCROLL,
    scrollX,
    scrollY,
  };
}

export function openModalAction(type, data) {
  return {
    type: OPEN_MODAL,
    modalType: type,
    modalData: data,
  };
}

export function closeModalAction() {
  return {
    type: CLOSE_MODAL,
  };
}

export function navScrollAction(item) {
  return {
    type: NAV_SCROLL,
    navActive: item,
  };
}
