/* eslint-disable import/prefer-default-export,react/prop-types */

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './src/state/configureStore';

const store = configureStore();

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>{element}</Provider>
);
