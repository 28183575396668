module.exports = [{
      plugin: require('/workspace/app/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-48264901-2"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"exclude":["/preview/**"]}},
    },{
      plugin: require('/workspace/app/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHWDNSR","includeInDevelopment":false},
    },{
      plugin: require('/workspace/app/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
